<template>
    <div class="bazijp-info wrapper">
        <Loading v-if="isLoading"></Loading>
        <form @submit.prevent="onCommit">
            <img src="@/assets/bazijp/images/info_head.png" width="100%">
            <div class="content">
                <div class="form-item">
                    <label>姓名</label>
                    <input type="text" placeholder="请输入姓名（必须填汉字）" v-model="name">
                </div>
                <div class="form-item">
                    <label>性别</label>
                    <div class="check-group">
                        <input class="form-check-input male" value="1" type="radio" v-model="gender">
                        <input class="form-check-input female" value="0" type="radio" v-model="gender">
                    </div>
                </div>
                <div class="form-item">
                    <label>生辰</label>
                    <input type="text" placeholder="请选择出生日期" ref="datetime" id="datetime" data-type="1" readonly="true"
                        data-confirm="true" data-toid-date="date" data-toid-hour="hour">
                </div>
                <div class="commit">
                    <input type="image" class="btn-cesuan" :src="require('@/assets/bazijp/images/btn_cesuan.png')">
                </div>
            </div>
            <img src="@/assets/bazijp/images/info_foot.png" width="100%">
            <input type="hidden" id="date">
            <input type="hidden" id="hour">
        </form>
        <div class="bottom">测算部分免费，获取报告或解锁全部内容时, 需付费查看。</div>
    </div>
</template>

<script>
import Loading from '../../components/Loading.vue'

export default {
    name: 'BzjpInfo',
    components: {
        Loading
    },
    data() {
        return {
            isLoading: false,
            name: '',
            gender: 1,
            datetime: ''
        }
    },
    mounted: function () {
        document.title = '生辰八字精批';
        new window.ruiDatepicker().init('#datetime');
    },
    methods: {
        isValidInput(text) {
            const chineseRegex = /^[\u4e00-\u9fa5]{2,4}$/
            return chineseRegex.test(text)
        },
        onCommit() {
            if (!this.isValidInput(this.name)) {
                alert('请正确输入姓名')
                return
            }
            const datetime = this.$refs.datetime;
            if (datetime.value.trim() === '') {
                alert('请选择出生日期')
            }
            let data = window.suanming(datetime.getAttribute("data-date"), datetime.getAttribute("data-hour"));
            data.username = this.name;
            data.gender = this.gender;
            const params = {
                name: this.name,
                gender: this.gender,
                datetime: datetime.value,
                date: datetime.getAttribute("data-date"),
                lunar_date: datetime.getAttribute("data-sdate"),
                hour: datetime.getAttribute("data-hour"),
                data: JSON.stringify(data),
                logid_url: localStorage.getItem("logid_url"),
                type: 'bazijp',
                return_url: this.$options.filters.getDomain() + '/bazijp/pay?oid=__OID__'
            }
            let that = this;
            this.$api.post('order/save', params).then(function (res) {
                if (res.data.success) {
                    that.$router.push({
                        path: 'pay',
                        query: {
                            oid: res.data.data.oid
                        }
                    })
                } else if (res.data.err_code) {
                    alert(res.data.err_msg);
                }
            }).catch(function (res) {
                console.log(res);
            })
            return false
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/bazijp/sass/info.scss';
</style>